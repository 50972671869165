<template>
  <div class="head-container">
    <!-- 搜索 -->
     <div class="filter-item">
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
            type="primary"
            class="filter-item" size="mini"
            icon="el-icon-close"
            @click="clear"
    >清空</el-button>
      <el-button
              v-if="getReshBtn()"
              type="primary"
              size="small"
              icon="el-icon-refresh-right"
              @click="refreshHandle"
              :loading="refreshLoading"
      >刷新今日数据
      </el-button>
      <el-button
              v-if="checkPermission(['EXPORT_DAILY_FINANCE_STATISTICS','admin'])"
              type="primary"
              size="small"
              icon="el-icon-download"
              @click="exportHandle"
              :loading="exportLoading"
      >导出表格
      </el-button>
  </div>
</template>

<script>
  import request from '@/utils/request';
import checkPermission from '@/utils/permission'
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      refreshLoading: false,
      exportLoading: false,
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    exportHandle() {
      this.$emit('export');
    },
    getReshBtn(){
      return checkPermission(['ADMIN','TFinanceData_ALL','TFinanceData_INIT']);
    },
    refreshHandle() {
      this.$confirm(`此操作会产生并覆盖今日数据`,'确认刷新', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
        //点击确定的操作(调用接口)
        this.refreshLoading = true;
        request({
          url: 'crm/initTFinanceData',
          methods: 'get',
        }).then((res) => {
          this.refreshLoading = false;
          this.$emit('toQuery');
        }).catch((err) => {
          this.refreshLoading = false;
        })
      }).catch(() => {
        //几点取消的提示

      });

    },
  }
}
</script>
