var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container financial-report"},[_c('eHeader',{ref:"header",attrs:{"query":_vm.query},on:{"toQuery":_vm.hasSummaryQuery,"clear":_vm.clear,"export":_vm.exportHandle}}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"size":"small","show-summary":"","summary-method":_vm.getSummaries}},[_c('el-table-column',{attrs:{"prop":"managerId","label":"日期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.time))])]}}])}),_c('el-table-column',{attrs:{"label":"出入金数据"}},[_c('el-table-column',{attrs:{"prop":"channelDeposit","label":"渠道入金"}}),_c('el-table-column',{attrs:{"prop":"commissionDeposit","label":"佣金入金"}}),_c('el-table-column',{attrs:{"prop":"otherDeposit","label":"其他入金"}}),_c('el-table-column',{attrs:{"prop":"successWithdraw","label":"当日出金"}}),_c('el-table-column',{attrs:{"prop":"applyWithdraw","label":"申请出金"}}),_c('el-table-column',{attrs:{"prop":"waitWithdraw","label":"待出金"}})],1),_c('el-table-column',{attrs:{"label":"交易数据"}},[_c('el-table-column',{attrs:{"label":"profit"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"profit-text",class:{
              on: _vm.isIncludeMinus(scope.row.profit),
              zero: _vm.getIsZero(scope.row.profit)
            }},[_vm._v(_vm._s(scope.row.profit))])]}}])}),_c('el-table-column',{attrs:{"prop":"commission","label":"commission"}}),_c('el-table-column',{attrs:{"prop":"swaps","label":"swaps"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"profit-text",class:{
              on: _vm.isIncludeMinus(scope.row.swaps)
            }},[_vm._v(_vm._s(scope.row.swaps))])]}}])}),_c('el-table-column',{attrs:{"prop":"orderProfit","label":"总盈亏"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"profit-text",class:{
            on: _vm.isIncludeMinus(scope.row.orderProfit),
            zero: _vm.getIsZero(scope.row.orderProfit)
          }},[_vm._v(_vm._s(scope.row.orderProfit))])]}}])})],1),_c('el-table-column',{attrs:{"label":"佣金数据"}},[_c('el-table-column',{attrs:{"prop":"orderVolume","label":"交易手数"}}),_c('el-table-column',{attrs:{"prop":"orderInternal","label":"总内佣"}}),_c('el-table-column',{attrs:{"prop":"orderForeign","label":"总外佣"}}),_c('el-table-column',{attrs:{"prop":"commissionOut","label":"当日出佣"}}),_c('el-table-column',{attrs:{"prop":"waitCommissionOut","label":"待出佣"}}),_c('el-table-column',{attrs:{"prop":"notCommissionOut","label":"未申请出佣"}})],1),_c('el-table-column',{attrs:{"label":"客户资产"}},[_c('el-table-column',{attrs:{"prop":"balance","label":"总余额"}}),_c('el-table-column',{attrs:{"prop":"worth","label":"总净值"}}),_c('el-table-column',{attrs:{"prop":"difference","label":"差额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"profit-text",class:{
              on:  _vm.isIncludeMinus(scope.row.difference),
              zero: _vm.getIsZero(scope.row.difference)
            }},[_vm._v(_vm._s(scope.row.difference))])]}}])})],1)],1),_c('public-pagination',{attrs:{"total-page":_vm.total,"current-page":_vm.page},on:{"pageChange":_vm.pageChange,"sizeChange":_vm.sizeChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }