import request from '@/utils/request'


export function getSummation(data) {
  return request({
    url: 'crm/getTFinanceDataSum',
    method: 'get',
    params: data,
  })
}


