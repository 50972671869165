<template>
  <div class="app-container financial-report">
    <eHeader
            ref="header"
      :query="query"
      @toQuery="hasSummaryQuery"
      @clear="clear"
      @export="exportHandle"
    ></eHeader>
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="managerId" label="日期">
        <template slot-scope="scope">
          <span>{{scope.row.time}}</span>
        </template>
      </el-table-column>
      <el-table-column label="出入金数据">
        <el-table-column prop="channelDeposit" label="渠道入金"/>
        <el-table-column prop="commissionDeposit" label="佣金入金"/>
        <el-table-column prop="otherDeposit" label="其他入金"/>
        <el-table-column prop="successWithdraw" label="当日出金"/>
        <el-table-column prop="applyWithdraw" label="申请出金"/>
        <el-table-column prop="waitWithdraw" label="待出金"/>
      </el-table-column>
      <el-table-column label="交易数据">
        <el-table-column label="profit">
          <template slot-scope="scope">
            <span class="profit-text" :class="{
                on: isIncludeMinus(scope.row.profit),
                zero: getIsZero(scope.row.profit)
              }">{{scope.row.profit}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="commission" label="commission"/>
        <el-table-column prop="swaps" label="swaps">
          <template slot-scope="scope">
              <span :class="{
                on: isIncludeMinus(scope.row.swaps)
              }" class="profit-text">{{scope.row.swaps}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderProfit" label="总盈亏">
          <template slot-scope="scope">
            <span :class="{
              on: isIncludeMinus(scope.row.orderProfit),
              zero: getIsZero(scope.row.orderProfit)
            }" class="profit-text">{{scope.row.orderProfit}}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="佣金数据">
        <el-table-column prop="orderVolume" label="交易手数"/>
        <el-table-column prop="orderInternal" label="总内佣"/>
        <el-table-column prop="orderForeign" label="总外佣"/>
        <el-table-column prop="commissionOut" label="当日出佣"/>
        <el-table-column prop="waitCommissionOut" label="待出佣"/>
        <el-table-column prop="notCommissionOut" label="未申请出佣"/>
      </el-table-column>
      <el-table-column label="客户资产">
        <el-table-column prop="balance" label="总余额"/>
        <el-table-column prop="worth" label="总净值"/>
        <el-table-column prop="difference" label="差额">
          <template slot-scope="scope">
            <span :class="{
                on:  isIncludeMinus(scope.row.difference),
                zero: getIsZero(scope.row.difference)
              }" class="profit-text">{{scope.row.difference}}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import { getSummation } from '@/api/stat/financialReport'
  import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
  import request from '@/utils/request';
  import { isIncludeMinus } from '@/utils/validate'
  import checkPermission from '@/utils/permission'
  import initDataComm from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/stat/financialReport/header'

  export default {
    name: 'financialReport',
    components: { eHeader },
    mixins: [initDataComm],
    props: {},
    data() {
      return {
        loading: false,
        statisticsSelf:{
          channelDepositSum: '',//	渠道入金	Y	浮点
          commissionDepositSum: '',//	佣金转入	Y	浮点
          otherDepositSum:'',	//其他入金	Y	浮点
          successWithdrawSum: '',//	出金金额	Y	浮点
          applyWithdrawSum: '',//	申请出金金额	Y	浮点
          waitWithdrawSum:'',//	待出金金额	Y	浮点
          orderProfitSum:'',//	实际盈亏	Y	浮点
          profitSum:'', //	profit	Y	浮点
          commissionSum: '',//	commission	Y	浮点
          swapsSum: '', //	swaps	Y	浮点
          orderVolumeSum: '',//	总手数	Y	浮点
          orderInternalSum: '',//	总内佣	Y	浮点
          orderForeignSum: '', //	总外佣	Y	浮点
          commissionOutSum: '',//	出佣	Y	浮点
          waitCommissionOutSum: '',//	待出佣	Y	浮点
          notCommissionOutSum: '', //	未申请出佣	Y	浮点
          worthSum: '',//	总净值	Y	浮点
          balanceSum: '',//	总余额	Y	浮点
        }
      }
    },
    created() {
      this.$nextTick(() => {
        this.init();
        this.getTotal();
      })
    },
    updated(){
      this.$nextTick(() => {
        this.setSumProfitColor();
      })
    },
    methods: {
      parseTime,
      isIncludeMinus,
      checkPermission,
      beforeInit() {
        this.url = '/crm/tFinanceData'
        this.params = {
          page: this.page,
          size: this.size,
        }
        if(this.query.startTime){
          this.params.startTime =  this.parseTime(this.query.startTime)
        }
        if(this.query.endTime){
          this.params.endTime =  this.parseTime(this.query.endTime)
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 1:
              sums[index] = `${this.statisticsSelf.channelDepositSum}`;
              break;
            case 2:
              sums[index] = `${this.statisticsSelf.commissionDepositSum}`;
              break;
            case 3:
              sums[index] = `${this.statisticsSelf.otherDepositSum}`;
              break;
            case 4:
              sums[index] = `${this.statisticsSelf.successWithdrawSum}`;
              break;
            case 5:
              sums[index] = `${this.statisticsSelf.applyWithdrawSum}`;
              break;
            case 6:
              sums[index] = '-';
              break;
            case 7:
              sums[index] = `${this.statisticsSelf.profitSum}`;
              break;
            case 8:
              sums[index] = `${this.statisticsSelf.commissionSum}`;
              break;
            case 9:
              sums[index] = `${this.statisticsSelf.swapsSum}`;
              break;
            case 10:
              sums[index] = `${this.statisticsSelf.orderProfitSum}`;
              break;
            case 11:
              sums[index] = `${this.statisticsSelf.orderVolumeSum}`;
              break;
            case 12:
              sums[index] = `${this.statisticsSelf.orderInternalSum}`;
              break;
            case 13:
              sums[index] = `${this.statisticsSelf.orderForeignSum}`;
              break;
            case 14:
              sums[index] = `${this.statisticsSelf.commissionOutSum}`;
              break;
            case 15:
              sums[index] = '-';
              break;
            case 16:
              sums[index] = '-';
              break;
            case 17:
              sums[index] = '-';
              break;
            case 18:
              sums[index] = '-';
              break;
            case 19:
              sums[index] = '-';
              break;
          }
        });

        return sums;
      },
      getTotal(){
        let params = {};
        if(this.query.startTime){
          params.startTime = parseTime(this.query.startTime);
        }
        if(this.query.endTime){
          params.endTime = parseTime(this.query.endTime);
        }
        getSummation(params).then((res) => {
          this.statisticsSelf = res;
        });
      },
      exportHandle() {
        this.$refs.header.exportLoading = true;
        request({
          url: '/crm/tFinanceData',
          methods: 'get',
          params: {
            export: true,
          }
        }).then((res) => {
          this.$refs.header.exportLoading = false;
          const tHeader =['渠道入金','佣金入金','其他入金','今日出金','申请出金', '待出金','profit','commission','swaps','总盈亏','交易手数','总内佣','总外佣','待出佣','未申请出佣','总余额','总净值','差额'];
          const filterVal =['channelDeposit','commissionDeposit','otherDeposit','successWithdraw','applyWithdraw', 'waitWithdraw','profit','commission','swaps','orderProfit','orderVolume','orderInternal','orderForeign','waitCommissionOut','notCommissionOut','balance','worth','difference'];
          const exportData = formatJson(filterVal,res);
          let fileName='财务日报表报表';
          export_json_to_excel(tHeader,exportData,fileName);
        }).catch((err) => {
          this.$refs.header.exportLoading = false;
        });
      },

      getIsZero(str){
        return str == '$0.00';
      },
      clear(){
        this.query = {};
        this.toQuery();
      },
      hasSummaryQuery(){
        this.toQuery();
        this.getTotal()
      },
      setSumProfitColor(){
        const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
        const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes;
        let elIndexArr = [7,9,10];
        child_tr_childivs.forEach((item,index) => {
          if(elIndexArr.includes(index)){
            let divEle = item.getElementsByClassName('cell')[0];
            if(divEle.innerText.indexOf('-') > -1){
              divEle.setAttribute('style', 'color: #E74B3A');
            }else {
              divEle.setAttribute('style', 'color: #11AC80');
            }
          }
        })
      },
    }
  }
</script>


<style lang="less" scoped>
  .space-bar {
    height: 20px;
  }
  .profit-text {
    color: #11AC80;
    &.on {
      color: #E74B3A;
    }
    &.zero {
      color: #606266;
    }
  }


</style>
<style lang="less">
    .financial-report {
        .el-table {
            display: flex;
            flex-direction: column;
        }

        /* order默认值为0，只需将表格主体order设为1即可移到最后，合计就上移到最上方了 */
        .el-table__body-wrapper {
            order: 1;
        }
    }
</style>
